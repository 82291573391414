import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import { LDFlag } from '@core/models/user-profile.model';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { defectType } from '@shared/constants/defect-type';
import { Status } from '@shared/models/status-type.model';
import { DefectsService } from '@todo/services/defects/defects.service';
import { map } from 'rxjs';

export interface NavbarMenuItem {
  icon: string;
  label: string;
  /** if routerLink is specified, it will redirect inside the application */
  routerLink?: string;
  /** if absoluteLink is specified, it will redirect to an external URL, triggering a page reload */
  absoluteLink?: string;
  /** default value is `true` */
  isVisible?: boolean;
  /** indicate that this item is a new addition and should be highlighted */
  isHighlighted?: boolean;
  /** indicate the highlight color */
  highlightType?: Status;
  /** count to be displayed inside the badge */
  badgeCount?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  defectReportItem: NavbarMenuItem;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private env: Environment,
    private defectService: DefectsService,
    private userService: UserStoreService
  ) {}

  getChecksItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-check',
    label: 'Kontroller', // Checks
    routerLink: '/check'
  });

  getChecklistsItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-checklist',
    label: 'Checklistor', // Checklists
    routerLink: '/checklist'
  });

  getTodoItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-todo',
    label: 'AttGöra', // To do
    routerLink: '/to-do'
  });

  getCalendarItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-calender',
    label: 'Kalender', // Calendar
    routerLink: '/calendar',
    isHighlighted: true,
    highlightType: Status.INFO
  });

  getDefectReportsItem = (): NavbarMenuItem => {
    this.defectReportItem = {
      icon: 'cui-scania-fms-defect-reports',
      label: 'Felrapporter',
      routerLink: '/defect-reports'
    };
    this.getDefectReportsToReviewCount();
    return this.defectReportItem;
  };

  getOverviewItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-overview',
    label: 'Översikt', // Overview
    routerLink: '/overview',
    isVisible: this.canAccessOverviewPage(),
    isHighlighted: true,
    highlightType: Status.INFO
  });

  getServiceCoverageItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-service-coverage',
    label: 'Servicetäckning', // Service coverage
    routerLink: '/service-coverage'
  });

  getWorkshopHistoryItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-workshop-history',
    label: 'Verkstadshistorik', // Workshop history
    routerLink: '/workshop-history'
  });

  getSettingsItem = (): NavbarMenuItem => ({
    icon: 'cui-myscania-app-settings',
    label: 'Inställningar', // Settings
    routerLink: '/settings'
  });

  getMenuItems(): NavbarMenuItem[] {
    const checkBeforeDriveItems = [this.getChecksItem(), this.getChecklistsItem()];
    const overviewItem = this.getOverviewItem();
    const calendarItem = this.getCalendarItem();
    const todoItem = this.getTodoItem();
    const otherItems = [
      this.getDefectReportsItem(),
      this.getServiceCoverageItem(),
      this.getWorkshopHistoryItem(),
      this.getSettingsItem()
    ];

    let items: NavbarMenuItem[] = [];
    if (this.canAccessOverviewPage()) {
      items = [overviewItem, todoItem, calendarItem, ...checkBeforeDriveItems, ...otherItems];
    } else {
      items = [...checkBeforeDriveItems, todoItem, calendarItem, overviewItem, ...otherItems];
    }

    return items.map((item) =>
      // each item is visible by default. it the isVisible property is undefined, set it to true
      Object.assign(item, { isVisible: item.isVisible === undefined ? true : item.isVisible })
    );
  }

  canAccessOverviewPage(): boolean {
    return this.userService.getLDFlagValue(LDFlag.OVERVIEW_PAGE);
  }

  getDefectReportsToReviewCount(): void {
    const params = { status: defectType.statusId.unreviewed };
    this.defectService
      .getCount(params)
      .pipe(map((response) => response.data || 0))
      .subscribe((count) => {
        if (count === 0) {
          return;
        }
        const badgeCount = count > 99 ? '99+' : count.toString();
        this.defectReportItem.isHighlighted = true;
        this.defectReportItem.highlightType = Status.DANGER;
        this.defectReportItem.badgeCount = badgeCount;
      });
  }
}
