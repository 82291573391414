export enum SPPermission {
  CREATE_EDIT_DELETE_SERVICE_PLANNING = 'CreateEditDeleteServicePlanning',
  SHOW_SERVICE_PLANNING_PAGE = 'ShowServicePlanningPage',
  DEFECT_REPORTING = 'DefectReporting',
  EDIT_DEFECT = 'EditDefect',
  READ_SERVICE_PLANNING = 'ReadServicePlanning'
}

export enum LDFlag {
  /** used to enable datadog session recordings, only if user consents via cookie */
  DATADOG = 'analytics-datadog',
  /** used to enable Zendesk support widget */
  ZENDESK = 'show-zendesk-widget',
  /** used to allow users to confirm workorders if certain criterias are met */
  BOOKED_WORK_ORDER = 'show-booked-work-order',
  /** allow user to report as performed from service events tab if no flexible maintenance is selected */
  PERFORM_NON_FLEXIBLE_MAINTENANCE = 'feature-report-as-performed-non-flexible-maintenance',
  /** enable new booking flow. this has the overview page access flag set as prerequisite */
  BOOKING_FLOW = 'feature-booking-flow',
  /** enable access to the overview dashboard */
  OVERVIEW_PAGE = 'feature-overview-page',
  /** show the DVSA checklist template as an option in the checklist templates page. Specific for UK market. */
  DVSA_CHECKLIST_TEMPLATE = 'feature-dvsa-checklist-template',
  /** show the static persisted missed checks instead of dynamically generated on each page refresh */
  STATIC_MISSED_CHECKS = 'feature-static-missed-checks'
}

export interface UserProfile {
  externalStaffReference: string;
  firstName: string;
  lastName: string;
  email: string;
  telephoneNumber: string;
  adLogin: string;
  title: string;
  customer: Customer;
  functionalPermissions: (SPPermission | string)[];
  userPermissions: (SPPermission | string)[];
  login: string;
  note: string;
  language: string;
  roles: string[];
  units: string;
  gasUnit: string;
  fuelConsumptionType: string;
  vehicleIdentifier: string;
  nationality: string;
  timeModify: string;
  hasPicture: boolean;
  hasUserAccount: boolean;
  timeLocked: boolean;
}

export interface Customer {
  externalCustomerReference: string;
  name: string;
  vatNumber: string;
  language: string;
  country: string;
  externalCountryReference: string;
  customerReference: string;
  changedBy: string;
  startOfWeek: string;
  units: string;
  fuelConsumptionType: string;
  timeZone: string;
  vehicleIdentifier: string;
  utcDiff: number;
  gasUnit?: string;
}
