import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {
  constructor(
    protected userService: UserStoreService,
    protected router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const requiredFeatureFlag = route.data['requiredFeature'] as string;
    // wait for the user to be authenticated
    return this.userService.isAuthenticated$.pipe(
      filter((isAuthenticated) => isAuthenticated),
      map(() => {
        // allow access if there's no `requiredFeature` property specified
        if (!requiredFeatureFlag) {
          return true;
        }

        const ldFlagValue = this.userService.getLDFlagValue(requiredFeatureFlag);

        if (!!ldFlagValue) {
          return true;
        }

        // redirect to default route if access is specified flag is not enabled
        return this.router.createUrlTree(['/']);
      })
    );
  }
}
